import { Point } from '@angular/cdk/drag-drop';
import { Rect } from '@shared';

/* action/reducer types */
export interface StatusContainer { status: boolean; }
export interface BreakpointContainer { breakpoint: BREAKPOINT; }
export interface UserStatus extends StatusContainer { username: string; accountName: string;}
export interface WidgetDataset { widgetName: string; dataSetName: string; }
export interface WidgetDatasetFrom extends WidgetDataset { url: string; transform?: (data: any)=>any}
export interface NamedWidgetDatasetFromItem extends WidgetDatasetFrom { keyName: string; itemName: string; }
export interface WidgetDatasetTo extends WidgetDatasetFrom { payLoad: any; }
export interface DatasetTo {
    url: string;
    key: string;
    data: any[];
    unid?: string;
    contentType?: string;
}

export interface NameValue { name: string; value: any; }
export interface DragLocation { point: Point; }
export interface Rectangle { rect: Rect; }
export interface LanguageContainer { language: string; }
export interface DashboardStateConfig { dashboardStateConfig: string[]; }
export interface PageMarker { page: { url: string; code: string }; }

export enum PAGES {
    GLOBAL = 'global',
    LANDING = 'landing'
}
export enum SECTION {
    VARIABLES = 'variables',
    DATA = 'data',
    PERSISTENCE = 'persistence'
}
export enum NAME {
    BREADCRUMBS = 'breadcrumbs',
    BOOKMARKS = 'bookmarks',
    CURRENT_CATEGORY = 'current-category',
    MENU = 'menu',
}

export interface BreadcrumbMenuItem {
    itemName: string;
    itemURL: string;
    itemKey?: string;
    itemAccURL?: string;
    itemDevURL?: string;
    itemDemoURL?: string;
    itemURLType?: string;
    itemURLTarget?: string;
}
export interface Breadcrumb {
    applicationID: string;
    applicationName: string;
    items: BreadcrumbMenuItem[];
    megamenuFocusArea: string;
    pageKey?: string;
    defaultBreadcrumb?: boolean;
    image?: string; //application image for landing page
}
export interface Bookcrumb extends Breadcrumb {
    bookmarkKey: string;
}
/*
mimic bootstrap
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
*/
export enum BREAKPOINT {
    md = 768,
    lg = 992,
    xl = 1200,
    larger = -1,
}
export const getBreakpoint = (width: number): BREAKPOINT => {
    if (width < BREAKPOINT.md) {
        return BREAKPOINT.md;
    } else if (width < BREAKPOINT.lg) {
        return BREAKPOINT.lg;
    } else if (width < BREAKPOINT.xl) {
        return BREAKPOINT.xl;
    } else {
        return BREAKPOINT.larger;
    }
}
export interface RoutingInfo {
    routingId: number;
    trigger: string;
}
export type TraceSettings = {
    store: boolean;
    routing: boolean;
    authentication: boolean;
    events: boolean;
    named?: string[];
}
// names of fixed trace domains above
export enum TRACE {
    STORE = 'store',
    ROUTING = 'routing',
    AUTHENTICATION = 'authentication',
    EVENTS = 'events',
    // add custom contexts
    MENU = 'menu'
}
export interface TraceLog {
    name: string;
    level: LogLevel;
    text: string;
}
export enum LogLevel {
    DEBUG, TRACE, WARN, ERROR
}


