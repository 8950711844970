import { OidcHelperService } from './oidc-helper.service';
import { Inject, Injectable } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AuthConfig, OAuthService, OAuthErrorEvent, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { environment } from '@e/environment';
import { globalVariableSelector, State, usernameSelector } from '@reducers';
import { PAGE, VAR, console, CustomEvents, CustomEventPayloadKeys } from '@shared';
import { ROUTE } from '@shared/routing';
import {
  setLoadingAuthStatus,
  setAuthenticated,
  setAuthenticated2FA,
  setPreferencesLoaded,
} from '@reducers/global/actions';
import { filter, first, take, defaultIfEmpty, map, catchError } from 'rxjs/operators';
import { clearAllWidgetDataSetCache } from '@reducers/widgets/actions';
import { Cookies } from '@app/utils/cookies';
import { TRACE } from '@reducers/types';
import { EMPTY, Observable } from 'rxjs';
import { CrossTabStorage } from './cross-tab-storage.service';

const env = environment;
const mockUsername = 'Dude Lebowski';
const mockAccountName = 'dude_lebowski';

@Injectable({
  providedIn: 'root'
})
export class WipoAuthService {
  activePage: string;
  private get myurl() {
    const u = window.location.href;
    return u.split('#')[0].split('?')[0];
  }
  private get redirectUrl(): string {
    switch (this.activePage) {
      // LANDING => ipportal => / or /home, redirect to dashboard
      case PAGE.LANDING: {
        return `${window.location.origin}${this.strategy.getBaseHref()}${ROUTE.DASHBOARD}`;
      }
      default:
        // all other case redirect back, except...
        // probably we will never use this obsolete route again(?)
        return environment.oidcPostLogoutRedirectUri === this.myurl
          ? environment.oidcRedirectUri
          : this.myurl;
    }
  }
  private authConfig: AuthConfig = {
    issuer: environment.oidcIssuer,
    clientId: environment.oidcClientId,
    redirectUri: this.redirectUrl,
    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: environment.oidcSilentRedirectUri,
    postLogoutRedirectUri: environment.oidcPostLogoutRedirectUri,
    scope: environment.oidcScope,
    responseType: 'code'
  };


  readonly MARKER_KEY = "IPP.UserIsAuth";

  private authenticated = false;
  private authenticated2fa = false;

  private loginLOA = '';
  private loginUsername = '';
  private loginAccountName = '';

  // trace: boolean;

  constructor(
    public http: HttpClient,
    private oauthService: OAuthService,
    private store: Store<State>,
    private location: Location,
    private router: Router,
    @Inject(LocationStrategy) private strategy: LocationStrategy,
    private oidcHelper : OidcHelperService
  ) {
    this.oauthService.setStorage(new CrossTabStorage());
  }

  public initialize() {

    if (environment.mockAuth) {
      console.logAs(TRACE.AUTHENTICATION,  `wipoAuthService: initialize: mockup authentication, 2fa: ${environment.mock2FA}`);
      this.authenticated2fa = environment.mock2FA;
      this.setAuthenticatedMode(mockUsername, mockAccountName, environment.mock2FA ? '3': '2');
      this.store.dispatch(setLoadingAuthStatus(false));
      return;
    }

    console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: initialize()');
    this.store.pipe(select(globalVariableSelector(VAR.activePage))).subscribe((p: string) => this.activePage = p);
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.errorAs(TRACE.AUTHENTICATION, 'wipoAuthService: OAuthErrorEvent:', event);
      } else if (event instanceof OAuthSuccessEvent && event.type === 'silently_refreshed') {
        console.logAs(TRACE.AUTHENTICATION,
          `wipoAuthService: OAuthEvent Access token REFRESHED, valid until: ${new Date(this.oauthService.getAccessTokenExpiration())}`);
      }
    });

    console.logAs(TRACE.AUTHENTICATION, 
      'wipoAuthService: initialize: wipo sso configure' + JSON.stringify(this.authConfig));

    this.oauthService.configure(this.authConfig);

    // setting up refresh of token
    const timeout = Cookies.get('refresh-timeout');
    if (timeout) {
      this.oauthService.timeoutFactor = +timeout;
    }
    console.logAs(TRACE.AUTHENTICATION, 
      `wipoAuthService: initialize: silent refresh timeout, ${this.oauthService.timeoutFactor}`);
    this.oauthService.setupAutomaticSilentRefresh();
    const errors = this.oauthService.events.pipe(
      filter(e => e instanceof OAuthErrorEvent && (e.type === 'silent_refresh_error' ||
        e.type === 'silent_refresh_timeout')),
      first()
    );
    errors.subscribe(e => {
      // logout
      console.errorAs(TRACE.AUTHENTICATION, 'wipoAuthService: OAuthErrorEvent event', e, 'logout');
      this.logout();
    });

    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(async _ => {
          const claims = this.oauthService.getIdentityClaims() as any;
          if(claims && claims.name) {
            this.getUserId().subscribe(userId => { 
              if (claims.name === userId) {
                console.logAs(TRACE.AUTHENTICATION, 'wipoAuthService: identity claims present');
                this.refreshIdentityClaimsVariables();
              } else {
                this.cleanOidcSessionStorage();
                console.logAs(TRACE.AUTHENTICATION, 'wipoAuthService: identity claims not present, redirecting to auth page');
                this.oauthService.initCodeFlow();
              }
          });
          } else {
              if(localStorage.getItem(this.MARKER_KEY) && this.oauthService.hasValidAccessToken()) {
                console.logAs(TRACE.AUTHENTICATION, 'wipoAuthService: the user is possibly authenticated in another tab. Retrieve and save their credentials in the current session.');
                this.refreshIdentityClaimsVariables(); 
              } else {
                const isCodeFound = await this.oidcHelper.isAuthorizationCodeFound(this.redirectUrl);
                if(isCodeFound) {
                  console.logAs(TRACE.AUTHENTICATION, 'wipoAuthService: the user is possibly authenticated on an other app.');
                  this.oauthService.initCodeFlow();
                } else {
                  this.setUnauthenticatedMode();
                }
              }
          }
      }).catch(_ => {
          console.errorAs(TRACE.AUTHENTICATION, 'wipoAuthService: initialize: error');
          this.setUnauthenticatedMode();
      });
  }

  getUserId(): Observable<string> {
    return this.http.get(`${environment.oidcUserinfo}`).pipe(
      map((claims: any) => {
        return claims['name'];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService - getUserId: user is not authenticated');
        } else {
          console.errorAs(TRACE.AUTHENTICATION, 'wipoAuthService - getUserId: error ', error);
        }
        this.setUnauthenticatedMode();
        return EMPTY;
      })
    );
  }

  private refreshIdentityClaimsVariables() {
    console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: refreshIdentityClaimsVariables()');
    const claims = this.oauthService.getIdentityClaims();
    console.logAs(TRACE.AUTHENTICATION, 
      `wipoAuthService: claims=${JSON.stringify(claims)}, Access token valid until, ${new Date(this.oauthService.getAccessTokenExpiration())}`);

    if (claims) {
      const username = claims['given_name'] + ' ' + claims['family_name'];
      const accountName = claims['sub'];
      this.setAuthenticatedMode(username, accountName, claims['loa']);
    } else {
      this.setUnauthenticatedMode();
    }
  }

  private cleanOidcSessionStorage() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('nonce');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('id_token_claims_obj');
    sessionStorage.removeItem('id_token_expires_at');
    sessionStorage.removeItem('id_token_stored_at');
    sessionStorage.removeItem('access_token_stored_at');
    sessionStorage.removeItem('granted_scopes');
    sessionStorage.removeItem('session_state');
    sessionStorage.removeItem('PKCE_verifier');
  }

  refreshUserinfo() {
    // loa refresh
    this.http.get(`${environment.oidcUserinfo}`)
      .subscribe((claims: any) => {
        const username = claims['given_name'] + ' ' + claims['family_name'];
        const accountName = claims['sub'];
        this.setAuthenticatedMode(username, accountName, claims.loa);
      }, e => {
        // do we set unauthenticated? or?
        console.errorAs(TRACE.AUTHENTICATION, 'AUTH REFRESH FAILED', e);
      });
  }
  private setAuthenticatedMode(username: string, accountName: string, loa: string) {
    this.loginUsername = username;
    this.loginAccountName = accountName;
    this.loginLOA = loa;
    this.authenticated = true;
    this.authenticated2fa = loa === '3';

    console.logAs(TRACE.AUTHENTICATION,  `wipoAuthService: set authenticated mode, loa: ${loa}, 2fa: ${this.authenticated2fa}`);
    // checking store for previous user in case of user switch to discard cache
    this.store.pipe(select(usernameSelector), take(1), defaultIfEmpty(undefined)).subscribe(lastUsername => {
      if (lastUsername && lastUsername !== this.loginUsername) {
        this.store.dispatch(setPreferencesLoaded(false));
        this.store.dispatch(clearAllWidgetDataSetCache());
        this.store.dispatch(setAuthenticated2FA(false, this.loginUsername, this.loginAccountName));
      }
      console.logAs(TRACE.AUTHENTICATION, 'auth', username, this.authenticated2fa)
      if (this.authenticated2fa) {
        this.store.dispatch(setAuthenticated2FA(true, this.loginUsername, this.loginAccountName));
      } else {
        this.store.dispatch(setAuthenticated(true, this.loginUsername, this.loginAccountName));
      }
    });
  }

  private setUnauthenticatedMode() {
    this.loginUsername = '';
    this.loginAccountName = '';
    this.loginLOA = '';
    this.authenticated = false;
    this.authenticated2fa = false;
 
    this.removeAuthenticatedFlag();
    this.cleanOidcSessionStorage();
    console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: set unauthenticated mode');
    this.store.dispatch(setAuthenticated(false));
    this.isTokenTimeOutOfSync();
  }

  removeAuthenticatedFlag(){
    localStorage.removeItem(this.MARKER_KEY);
  }

  public isTokenTimeOutOfSync() {
    const token = this.oauthService.getAccessToken();
    if (!token) {
      console.warn('Access token is missing or undefined.');
      return false;
    }
    const { exp, iat } = this.decodeToken(token);
    if (!exp || !iat) {
      console.warn('Invalid token content. Missing exp or iat.');
      return false;
    }
    const currentTime = Math.floor(Date.now() / 1000); // current time in Unix epoch

    // Check if the current time is within the allowed range
    if (currentTime < iat) {
      console.warn('Token time is out of sync with the client time. Please check your system clock.');
      return true;
    }
    return false;
  }

  private decodeToken(token) {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      return {
        exp: decodedToken.exp,
        iat: decodedToken.iat
      };
    } catch (error) {
      console.error('Error decoding token:', error);
      return {};
    }
  }

  public loginWithUserPassword(state?: string) {
    if (env.mockAuth) {
      console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: mock loginWithUserPassword()');
      this.setAuthenticatedMode(mockUsername, mockAccountName, '3');
    } else {
      // make sure we do not use FALSE as auth value when returning, but wait until verified
      this.store.dispatch(setLoadingAuthStatus(true));
      console.logAs(TRACE.AUTHENTICATION, 
        `wipoAuthService: loginWithUserPassword() STATE ${state}, REDIRECT ${this.redirectUrl} source ${this.activePage}`);
      this.oauthService.redirectUri = this.redirectUrl;
      this.oauthService.customQueryParams = { loa: '2' }; // 2 = basic authentication, user and password
      this.oauthService.initCodeFlow(state);
    }
  }

  public loginWith2FA() {
    if (env.mockAuth) {
      console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: mock loginWith2FA()');
      this.setAuthenticatedMode(mockUsername + ' (2FA)', mockAccountName, '3');
    } else {
      console.logAs(TRACE.AUTHENTICATION, 
        `wipoAuthService: loginWith2FA(), REDIRECT ${this.redirectUrl} source ${this.activePage}`);
      this.store.dispatch(setLoadingAuthStatus(true));
      this.oauthService.redirectUri = this.redirectUrl;
      this.oauthService.customQueryParams = { loa: '3' }; // 3 = 2FA
      this.oauthService.initCodeFlow();
    }
  }

  public logout() {
    if (env.mockAuth) {
      this.authenticated = false;
      this.authenticated2fa = false;
      console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: MOCK logout(), forwarding to ROOT');
      this.router.navigate([ROUTE.ROOT]);
    } else {
      console.logAs(TRACE.AUTHENTICATION,  'wipoAuthService: logout()');
      this.removeAuthenticatedFlag();
      this.oauthService.logOut();
      //send logout evnt to navbar
      const updateEvent = new CustomEvent(CustomEvents.IPPORTAL_UPDATE, {
        detail: {
          [CustomEventPayloadKeys.USER_LOGGED_OUT]: true
        }
      });
      window.dispatchEvent(updateEvent);
    }
  }
}