import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import {
  HttpClientModule,
  HttpClient
} from '@angular/common/http';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  CommonModule,
  DatePipe,
} from '@angular/common';

import { OAuthModule } from 'angular-oauth2-oidc';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';


import { DirectivesModule } from './directives/directives.module';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { CustomMissingTranslationHelper } from './utils/custom-missing-translation-handler';

import { StoreModule, Store } from '@ngrx/store';
import { reducers, metaReducers, State } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { initApplication } from './reducers/global/actions';
import { loadNavbar } from './utils/loader';
import { VersionComponent } from './pages/version/version.component';
import { environment } from 'environments/environment';
import { MultiTranslateLoaderService } from '@core/multi-translate-loader.service';
import { console } from '@shared';

import { httpInterceptorProviders } from './_interceptors';

// import { BehaviorServiceConfig } from '@wipo/w-angular/shared';

import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeAr from '@angular/common/locales/ar';
import localeZh from '@angular/common/locales/zh';
import localeRu from '@angular/common/locales/ru';
import localeDe from '@angular/common/locales/de';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';

export function onAppInit(store: Store<State>): () => void {
  console.log("Init App called !");
  return () => store.dispatch(initApplication());
}


@NgModule({
  declarations: [
    AppComponent,
    VersionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    OAuthModule
      .forRoot
      ({
        resourceServer: {
          allowedUrls: [
            'https://patentscope.wipo.int',
            `${environment.oidcUserinfo}`,
            `${environment.wipoProofUrl}`,
            `${environment.urlPersistanceAWS}`,
            `${environment.globalBrandDbAPIUrl}`
          ],
          sendAccessToken: true
        }
      }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MultiTranslateLoaderService,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHelper }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    EffectsModule.forRoot([AppEffects]),
    DirectivesModule
  ],
  providers: [
    Location,
    ...httpInterceptorProviders,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // example code to override w-angular mobile detection rules
    // {
    //   provide: BehaviorServiceConfig,
    //   useFactory: watchMobile,
    //   deps: [Store]
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [Store]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => environment.loadNavbar ? loadNavbar : () => { },
      multi: true
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private store: Store) {
    console.store = this.store;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeAr, 'ar');
    registerLocaleData(localeZh, 'zh');
    registerLocaleData(localeRu, 'ru');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeJa, 'ja');
    registerLocaleData(localeKo, 'ko');
    registerLocaleData(localePt, 'pt');
    console.logAs('ipportal', 'environment:', environment.name);
  }
}
