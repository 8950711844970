import { LocationStrategy } from '@angular/common';
import { environment } from '@e/environment';
import { Store } from '@ngrx/store';
import { traceLog } from '@reducers/global/actions';
import { Bookcrumb, LogLevel } from '@reducers/types';
import { CURRENT_ENVIRONMENT } from '@app/utils/lib';
import { WOption } from '@wipo/w-angular/shared';

export const con = window.console;

export interface Rect {
  height: number; width: number;
}

export enum VAR {
  showMegamenu = 'showMegamenu',
  disableBell = 'disableBell',
  activePage = 'activePage'
}
export enum PAGE {
  ABOUT = 'about',
  DASHBOARD = 'dashboard',
  LANDING = 'ipportal',
  ALERTS = 'alerts'
}
export enum CustomEvents {
  APP_BOOKMARK_CLICK = 'wipoAppBookmarkedClick',
  LOGIN_CLICK = 'wipoLoginClick',
  MEGAMENU_TOGGLE = 'wipoToggleMegamenu',
  LOGOUT_CLICK = 'wipoLogoutClick',
  WIDGET_ADD = 'wipoWidgetAdd',
  TOGGLE_MENU = 'wipoToggleWidgetMenu',
  CLEAR = 'wipoDashboardClear',
  TEMPLATE = 'wipoWidgetAddTemplate',
  API_REQUEST = 'wipoAPIRequest',
  BUTTON_CLICK = 'wipoButtonClick',
  FEEDBACK_SUBMITTED = 'wipoFeedbackSubmitted',
  ROUTING_FINISHED = 'wipoRoutingFinished',
  LINK_CLICKED = 'wipoIPPortalLinkClick',
  WIDGET_CATEGORY = 'wipoToggleWidgetCategory',
  WIDGET_REMOVED = 'wipoWidgetRemove',
  ACTION_CLICKED = 'wipoActionClick',
  PORTAL_LINK_CLICK = 'wipoIPPortalLinkClick',
  PROMO_LINK_CLICKED = 'wipoPromoLinkClick',
  MESSAGE_CLICKED = 'wipoMessageClick',
  MESSAGE_SEARCHED = 'wipoSearch',
  MESSAGE_SELECTED = 'wipoMessageToggleSelect',
  WIDGET_DROPDOWN_TOGGLED = 'wipoWidgetDropdownToggle',
  WIDGET_DROPDOWN_OPTION_CLICKED = 'wipoWidgetDropdownOptionClick',
  WIDGET_BODY_CLICK = 'wipoWidgetBodyClick',
  LANGUAGE_CHANGE = 'wipoLanguageChange',
  // sending to Navbar
  IPPORTAL_UPDATE = 'wipoIpportalUpdate',
}
export enum CustomEventPayloadKeys {
  BOOKMARKS = 'bookmarks', // can announce what has been updated
  ALERTS = 'alerts',
  USER_LOGGED_OUT = 'userLoggedOut',
}
export const getOption = (label: string, value: string): WOption => {
  return { label, value };
};


export enum WIDGET {
  EPCT_PENDING = 'epct.pending',
  EPCT_TIME_LIMITS = 'epct.time_limits',
}
export const widgetRequires2FA = (type: WIDGET) => {
  return [WIDGET.EPCT_PENDING, WIDGET.EPCT_TIME_LIMITS].includes(type);
};
export const getMomentLocale = (language: string) => {
  return environment.localeMap[language] ? environment.localeMap[language] : language;
};

export interface StickyBarLink {
  label: string;
  route: string | string[];
  altRoutes?: string[];
  selected: boolean;
}

export interface MenuAppEntry extends MenuItem {
  applicationID: string; // new navbar uses appId
  pageKey: string; // if app has several entry points, we use pageKey to distinguish (breadcrumbs, favorites etc)
  key: string; // old navbar key
  index?: number; // index in breadcrumb (if not specified the last entry)
  description: string;
  applicationName: string;
  itemName: string;
  itemURL: string;
  itemAccURL?: string;
  itemDevURL?: string;
  itemDemoURL?: string;
  keywords?: [];
  image?: string;
}
export interface MenuSubCat {
  title: string;
  key: string;
  apps: MenuAppEntry[];
}
export interface MenuCat {
  title: string;
  description: string;
  key: string;
  image: string;
  subcategories: MenuSubCat[];
}
export interface MenuItem {
  itemName: string;
  itemURL: string;
  itemKey?: string;
  itemAccURL?: string;
  itemDevURL?: string;
  itemDemoURL?: string;
  itemURLType?: string;
  itemURLTarget?: string;
}
export interface CrumbMenuItem extends MenuItem {
  bookmarkKey: string;
}
export interface LinkInfo {
  name: string; link: string; tab: string;
  bookmarkKey: string;
}
export const getImage = (location: LocationStrategy, type: string, file: string) => {
  if (file?.startsWith('http')) {
    return file;
  } else {
    return file ? `${location.getBaseHref()}assets/images/${type}/${file}` : '#';
  }
};
export const menuItemFromBreadcrumb = (m: Bookcrumb): CrumbMenuItem => ({
  ...m.items[m.items.length - 1],
  itemName: m.applicationName,
  bookmarkKey: m.bookmarkKey
});
const menuItemURLField = {
  DEV: 'itemDevURL',
  TEST: 'itemAccURL',
  DEMO: 'itemDemoURL',
  LIVE: 'itemURL',
};
const menuItemURLKeys = Object.keys(menuItemURLField);
export const getMenuItemLink = (item: MenuItem) => {
  const idx = menuItemURLKeys.indexOf(CURRENT_ENVIRONMENT);
  let link = item.itemURL;
  for (const key of menuItemURLKeys) {
    if ((idx === -1 || menuItemURLKeys.indexOf(key) >= idx) &&
      menuItemURLField[key] &&
      item[menuItemURLField[key]]
    ) {
      link = item[menuItemURLField[key]];
      break;
    }
  }
  return link;
};
const BASE_STYLE = 'border-radius: 4px; color: white; border: 1px solid white;padding: 1px 2px;';
export const STYLE = {
  LOG: `background-color:#85bcff; ${BASE_STYLE}`,
  META_TRACE: `background-color:#f5d142; ${BASE_STYLE}`,
  ACTIONS_TRACE: `background-color:pink; ${BASE_STYLE}`,
  ACTIONS_ALERT: `background-color:red; ${BASE_STYLE}`
}
const traceObject = (o: any, cached = new Map()) => {
  if (cached.has(o)) { return cached.get(o); }
  const type = typeof o;
  if (Array.isArray(o)) {
    return o.map(v => traceObject(v, cached));
  } else if (o === null || o === undefined) {
    return o;
  } else if (type === 'object' && Object.getPrototypeOf(o)) {
    const className = Object.getPrototypeOf(o).constructor.name;
    const copy = {};
    if (className !== 'Object') {
      copy['_className'] = className;
    }
    cached.set(o, copy);
    Object.entries(o).forEach(([key, child]) => {
      copy[key] = traceObject(child, cached);
    });
    return copy;
  } else if (type === 'function') {
    const isClass = o.toString().startsWith('class');
    const isComponent = Object.keys(o).includes('__NG_ELEMENT_ID__');
    const name = isComponent ? '_ngComponentName' : (isClass ? '_className' : '_functionName');
    return Object.entries(o).filter(([k, v]) => v !== o).reduce((copy, [k, v]) => (
      { ...copy, [k]: traceObject(v, cached) }), { [name]: o.name });
  } else {
    return o;
  }
}
export class ConsoleLogger {
  store: Store;
  public record(level: LogLevel, name: string, text: string, ...args) {
    this.store?.dispatch(traceLog(level, name, text, ...traceObject(args)));
  }
  public debug(text: string, ...args: any[]) {
    this.debugAs('-', text, ...args);
  }
  public debugAs(name: string, text: string, ...args: any[]) {
    this.record(LogLevel.DEBUG, name, text, ...args);
  }
  public log(text: string, ...args: any[]) {
    this.logAs('-', text, ...args);
  }
  public logAs(name: string, text: string, ...args: any[]) {
    this.record(LogLevel.TRACE, name, text, ...args);
  }
  public warn(text: string, ...args: any[]) {
    this.warnAs('-', text, ...args);
  }
  public warnAs(name: string, text: string, ...args: any[]) {
    this.record(LogLevel.WARN, name, text, ...args);
  }
  public error(text: string, ...args: any[]) {
    this.errorAs('-', text, ...args);
  }
  public errorAs(name: string, text: string, ...args: any[]) {
    this.record(LogLevel.ERROR, name, text, ...args);
  }
  public group(name: string) {
    con.group(name);
  }
  public logGroup(name: string) {
    con.group(name);
  }
  public groupEnd() {
    con.groupEnd();
  }
}
export const console = new ConsoleLogger();
export const sortByProperty = (property: string) => {
  return function (x, y) {
    return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));
  };
};
export const addIf = (a: string, b: string): string => {
  return a && b ? `${a}+${b}` : (a ? a : b);
}
export const concatQueryParams = (pfxSearch: string, ...params: string[]): string => {
  const qs = params.filter(p => !!p).join('&');
  return qs ? `${params[0] ? pfxSearch : ''}?${qs}` : '';
}